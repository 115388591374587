<template>
    <div>
        <Header></Header>
        <div class="pric">
            <h2 class="title">Privacy Policy</h2>
            <p>This Privacy Policy applies to all of our games, whether played on mobile devices, PCs or other platforms. It also applies to our marketing and advertising activities on all platforms and other services we may provide to you from time to time. In this Privacy Policy, we refer to our games, website, marketing and advertising campaigns and other services collectively as the "Services".</p>
            <p>Now the website is owned and operated by Playfunfun Limited <strong>(FLAT A516, 5/F, EFFICIENCY HOUSE, 35 TAI YAU STREET, SAN PO KONG, KOWLOON, HONG KONG)</strong></p>
            <p>GOVERNING LAW- These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the HK laws.</p>
            <p>By visiting and/or playing our games, or interacting with our website or other services, you agree to the collection and use of your information in accordance with this Privacy Policy, including consent to the use of your data by PlayFun game and our advertising partners for this Privacy Policy Targeted advertising purposes as described in the Marketing and Advertising section of the Policy. These sections also explain how you can refuse or withdraw your consent to the use of your data for targeted advertising purposes. You should not use our games or other services if you have any other concerns about providing information to us or being used for the purposes described in this Privacy Policy.</p>
            <h2 class="title">Collection and Use</h2>
            <p>When you play games or watch videos, we process information about these activities. This includes information such as IP address, games you've played, advertising IDs, general browser and device information (such as brand and type), and country. The information we request will be retained by us and used in accordance with this Privacy Policy.</p>
            <p>Our Privacy Policy contains more detailed information about: with whom we share your information and why; the steps we take to keep your information secure; the legal basis on which we process your data; how long we keep your information and where we store it; when and why we share your information with third parties, including transfers outside the European Economic Area (“EEA”); and changing or updating our Privacy Policy .</p>
            <h2 class="title">Cookies</h2>
            <p>Cookies are files containing small amounts of data, often used as an anonymous unique identifier. This content is sent to your browser from the websites you visit and stored in your device's internal memory.</p>
            <p>The service does not expressly use these "cookies". However, the app may use third-party code and libraries that use "cookies" to collect information and improve the service. You can choose to accept or reject these cookies and know when a cookie is sent to your device. If you choose to refuse our cookies, you may not be able to use some parts of the Service.</p>
            <h2 class="title">Playing Our Games</h2>
            <p>By visiting and/or playing our games or using our other services, you consent to our collection of information about you. Most of this data comes directly from you or your device, such as device information, country and region, and game information. We use this data to provide you with our games and services, including for optimization and fraud prevention purposes. We may also use it for other purposes set out in this Privacy Policy, including marketing and advertising purposes.</p>
            <h2 class="title">Visiting our website</h2>
            <p>When you visit our other websites and other websites and forums that we operate (the "Sites"), we collect information about you, such as details about how you use these websites and your interactions with them . We may use the information we collect about you for a variety of purposes, including to operate and optimize the website, in connection with our advertising purposes, and for research or analysis.</p>
            <h2 class="title">Personal data</h2>
            <p>PlayFun game may use third party service providers who collect your personal data in the name of PlayFungame or on behalf of the game, orPlayFungamewill share your data with them in a secure manner, especially to ensure that necessary tasks are performed to perform place your order, deliver your product, process your payment, ensure customer relationship management, provide you with a chat service, combat payment fraud, advertise your service or provide you with a survey.</p>
            <h2 class="title">Children</h2>
            <p>You must be over a certain age to play our games and use our services, depending on where you live. We do not knowingly collect or solicit personal information from anyone in the following age groups, nor do we knowingly serve interest-based advertising to them, or knowingly allow these persons to use our services. Please contact us if you think we may have information about a child under the following age.</p>
            <h2 class="title">Marketing</h2>
            <p>Unless you opt out as described below, we and our third party partners use the information collected about you to send you marketing communications about other PlayFungame and related services based on your interests. For example, to serve ads on our Services, or to serve ads on third-party websites, applications, and connected devices.</p>
            <h2 class="title">Advertising</h2>
            <p>Our games may include advertisements for third-party products and services.</p>
            <p>PlayFungame and our advertising partners collect and use information about you to improve advertising, targeting and measurement systems so that we can show you relevant third-party advertisements in our games.</p>
            <p>Where applicable, we use CMP to collect consent and permission for our advertising-based activities. Where permitted by local law, we may default to interest-based advertising.</p>
            <h2 class="title">Rights</h2>
            <p>You have certain rights regarding your personal information and how we process it. You can exercise these rights at any time by contacting us through any of the methods listed in the "Contact Us" section below.</p>
            <h2 class="title">Updates</h2>
            <p>We reserve the right to modify this Privacy Policy at any time. Therefore, we invite you to periodically review our "Privacy Policy".</p>
            <h2 class="title">Contacting us</h2>
            <p>If you have any questions or concerns about any matter related to this Privacy Policy, including exercising any of your rights, you may contact us through payment@playfungo.com.</p>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
    name: "PricPage",
    components: {
        Header, Footer
    }
}
</script>

<style lang="scss" scoped>
.pric {
    width: 100%;
    padding: .4688rem 2.3438rem;
    background-color: #1a1a1a !important;

    .title {
        color: #fff;
        font-size: 30px;
        margin: 0 0 50px;
    }

    p {
        padding: 0 0 15px;
        font-size: 16px;
        color: #fff;
        line-height: 33px;

    }

    .email {
        color: #2987d4;
        text-decoration: none;
    }
}
</style>