<template>
  <div>
    <Header :message="'2'"></Header>
    <div class="container">
      <div class="bannerbox">
        <!-- 轮播图-游戏展示区域 -->
        <div class="featured"  data-wow-duration="2s" style="display: flex;">
          <h3 style="font-size: .25rem; color: #fff;">Featured Game</h3>
          <a href=""><span>View all</span><i style="display: block; border-radius: 100; color: #Ed9A21;"
              class="el-icon-right" /></a>
        </div>
        <div class="carousel  wow animate__animated animate__zoomInUp" data-wow-offset="300" data-wow-delay="2s" style="position: relative; width: 7.8125rem; margin:auto; padding: .3646rem 0;">
          <el-carousel :interval="0" indicator-position="none" arrow="always" height="3.6458rem">
            <el-carousel-item v-for="item in images" :key="item.url">
              <div class="banner_box">
                <div class="show_game">
                  <div class="show_game_up">
                    <div class="show_game_up_left">
                      <img :src="item.logo" alt="">
                    </div>
                    <div class="show_game_up_right">
                      <h3 class="show_game_up_right_title">{{ item.name }}</h3>
                      <p class="show_game_up_right_p">
                        <span class="show_game_up_right_p_span">Space Shooting</span>
                        <i class="iconfont icon-icon-user"></i>
                        <span>100.000.000+</span>
                      </p>
                    </div>
                  </div>
                  <p class="show_game_p1">{{ item.gameText }}</p>
                  <p class="show_game_p2"><a href="https://playfungo.com/" style="text-decoration: none; color: #fff;" target="_blank">More..</a></p>
                </div>
                <div class="banner_box_right">
                  <a :href="item.hrefUrl" target="_blank"><el-button style="font-size: .0729rem;" size="mini">START THE GAME <i style="font-size: 15px;" class="iconfont icon-dianji"></i></el-button></a>
                  <!-- <img src="../../assets/images/game_store_ggplay.webp" alt="">
                  <img src="../../assets/images/game_store_aphone.webp" alt=""> -->
                </div>
              </div>
              <img :src="item.url" class="bannerimg" style="width: 100%; height: 100%; border-radius: .0521rem; opacity: .4; object-fit: cover" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
    name:'GAME',
    components: {
    Header,
    Footer,
  },
  data() {
    return {
      images: [
          { 
            url: require("@/assets/images/banner_1.png"),
            name:'playfungo',
            number:'',
            logo:require("@/assets/images/logo.png"),
            gameText:'Discover an engaging and captivating online platform filled with a plethora of delightful mini-games, guaranteed to provide endless entertainment and excitement.' ,
            hrefUrl:"https://playfungo.com/",
          },
        ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);

.banner_box{
  position: absolute;
  top: 50%;
  left: .9375rem;
  width: 5.9375rem;
  display: flex;
  .banner_box_right{
    position: absolute;
    z-index: 9;
    top: 55px;
    left: 640px;
    // img{
    //   cursor: pointer;
    //   width: 1.1354rem;
    //   height: .3333rem;
    // }
  }
}
.banner_logo{
  width: .6667rem;
  height: .6667rem;
}
.show_game{
  height: 2.5521rem;
  width: 1.7188rem;
  position: absolute;
  z-index: 9;
  top: -300px;
  left: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end
}
.show_game_p1{
  font-size: .0729rem;
  color: rgb(233, 233, 233);
  margin: .0521rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
}
.show_game_p2{
  font-size: .0833rem;
  color: #fff;
}
.show_game_up{
  display: flex;
  justify-content: space-evenly;
  padding-top: .1042rem;
}
.show_game_up_right{
  padding: 0 0 0 .0521rem;
  height: .3646rem;
}
.show_game_up_right_title{
  color: #fff;
  font-size: .1563rem;
  line-height: .1354rem;
  padding: 0 0 .0521rem;
  width: 1.25rem;
  line-height: .1823rem;
}
.show_game_up_right_p>i{
  margin: 0 0 0 .1563rem;
  color: #ffffdd9e;
}
.show_game_up_right_p_span{
  font-size: .0729rem;
  color: #d5d3d3;
}
.show_game_up_right_p:last-child{
  font-size: .0729rem;
  color: #d5d3d3;
}
.show_game_up_left{
  width: 100px;
  height: 100px;
  border-radius: 15px;
  background-color: #FFF;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: .3646rem;
    height: auto;
  }
}
.showgame{
  width: 5.9375rem;
  margin: 0 auto;
}



.showgame_tit{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: .1563rem;
  .showgame_tit_left{
    h2{
      font-size: .2083rem;
      color: #FB9C2C;
    }
    p{
      font-size: .0833rem;
      color: #B3B3B3;
      width: 3.125rem;
    }
  }
}
.data_left,.data_center,.data_right{
  display: flex;
  align-items: center
}
.data_left_img{
  width: .2083rem;
  height: .3333rem;
}
.data_left_number{
  margin-left: .1042rem;
  p{
    font-size: .2708rem;
    line-height: .3802rem;
    color: #fff;
    font-weight: bold;
  }
  h3{
    color: #B3B3B3;
    font-size: .1667rem;
    line-height: .2188rem;
  }
}
.showgame_box{
  width: 5.9375rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  border: .0052rem solid #FB9C2C;
  border-radius: .0521rem;
}
.game_item{
  width: 1.3281rem;
  height: 1.8906rem;
  border-radius: .0521rem;
  margin: .0521rem .0729rem;
  background-color: #fff;
  text-align: center;
  position: relative;
  .game_item_bgimg{
    border-radius: .0521rem;
    width: 100%;
    height: auto;
    opacity: .6;
  }
  .game_item_name{
    margin-top: .1563rem;
    cursor: pointer;
    font-size: .1042rem;
    color: #fff;
    transition: color .3s ease;
  }
  .game_item_name:hover{
    color: #FB9C2C;
  }
  .game_item_hover{
    position: absolute;
    top: .5729rem;
    left: .3906rem;
  }
  .game_item_hoverimg1{
    width: .2604rem;
    height: .2604rem;
    border-radius: .026rem;
  }
  .game_item_hoverimg2{
    width: .5208rem;
    height: auto;
  }
  .game_item_hoverp{
    color: #fff;
    margin: .0521rem 0;
  }
}

</style>