<template>
    <div class="foot">
      <div class="footer">
        <div class="container">
          <div class="footer_logo">
            <!-- <img src="@/assets/images/logo.png" alt=""> -->
          </div>
          <div class="footer_nav">
            <div class="footer_nav_left">
              <div class="footer_icon">
                <ul class="footer_icon_ul">
                  <li><i class="iconfont icon-facebook"></i></li>
                  <li><i class="iconfont icon-linkedin"></i></li>
                  <li><i class="iconfont icon-app-store-line"></i></li>
                  <li><i class="iconfont icon-GooglePlayLogo"></i></li>
                  <li><i class="iconfont icon-twiter"></i></li>
                  <li><i class="iconfont icon-Youtube-fill"></i></li>
                </ul>
              </div>
              <div class="footer_menu">
                <ul>
                  <li><router-link class="footer_menu_ul_li" to="/terms.html">Term of Service</router-link></li>
                  <li><router-link class="footer_menu_ul_li" to="/pric.html">Privacy Policy</router-link></li>
                </ul>
              </div>
            </div>
            <div class="footer_nav_right">
              <div class="footer_nav_title">Company</div>
              <ul class="footer_nav_ul">
                <li @click="toAboutUs()">About us</li>
                <li @click="toCareer()">Career</li>
                <!-- <li><router-link class="footer_menu_ul_li" to="">Contact us</router-link></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Footer',
    data(){
      return{
      }
    },
    methods:{
      toAboutUs(){
        this.$router.push({name:"aboutus"}).catch(err => err);
      },
      toCareer(){
        this.$router.push({name:'career'}).catch(err => err);
      }
    },
}
</script>

<style lang="scss" scoped>
.foot {
    width: 100%;
    background-color: #000;
}

.footer {
    padding-top: .2604rem;
    width: 6.25rem;
    margin: 0 auto;
}
.footer_logo{
  padding: .1732rem 0;
  img{
    width: .8542rem;
    height: .6302rem;
  }
}
.footer_nav{
  display: flex;
  justify-content: space-between;
}
.footer_nav_left{
  width: 2.8125rem;
  color: #666;
  font-size: .0729rem;
  margin-right: .3281rem;
}
.footer_nav_right{
  width: 2.8125rem;
}
.iconfont{
  color: #b3b3b3;
  font-size: .1302rem;
}
.footer_icon_ul{
  list-style: none;
  display: flex;
  align-items: center;
  li{
    list-style: none;
    margin-right: .1563rem;
    transition: color .5s ease;
  }
  li:hover{
    color: #ED9A21;
  }
}
.footer_menu{
  text-decoration: none;
  color: #999;
  padding: .2604rem 0;
  li{
    cursor: pointer;
    list-style: none;
    font-size: .0833rem;
    line-height: .2604rem;
    transition: color .5s ease;
  }
  li:hover{
    color: #ED9A21;
  }
}
.footer_menu_ul_li{
  transition: color .5s ease;
  color: #999;
  text-decoration: none;
}
.footer_menu_ul_li:hover{
    color: #ED9A21;
  }
.footer_nav_title{
  line-height: .1563rem;
  font-size: .0938rem;
  color: #000;
  
}
.footer_nav_ul{
  li{
    cursor: pointer;
    list-style: none;
    color: #999;
    font-size: .0833rem;
    line-height: .2604rem;
    transition: color .5s ease;
  }
  li:hover{
    color: #ED9A21;
  }
}
.footer{
  background-image: -webkit-linear-gradient(-30deg,
            transparent 0%,
            transparent 60%,
            rgb(24, 24, 24) 60%,
            rgb(32, 32, 32) 75%,
            transparent 75%,
            transparent);
            margin-top: .2604rem;
}

</style>