<template>
  <div>
    <Header :message="'4'"></Header>
    <div class="career">
      <!-- <div class="career_banner">
        <div class="banner_left  wow animate__animated animate__slideInRight "></div>
        <div class="banner_right wow animate__animated animate__slideInLeft"></div>
        <div class="career_banner_left wow animate__animated animate__fadeInDown ">
          <h3>{{ life1 }}</h3>
          <h4>{{ life2 }}</h4>
          <p>{{ life3 }}</p>
        </div>
        <div class="career_banner_right wow animate__animated animate__fadeInDown ">
            <img class="career_banner_right_img" :src="require(`@/assets/images/Career_zhanshilan610_356_${img_index}.png`)" alt="">
        </div>
        <div class="career_banner_down">
          <div class="scroll_bg  wow animate__animated animate__fadeIn ">
            <div style="margin:.1042rem .5208rem .5208rem ; display: flex; justify-content: space-evenly; width: 4.1667rem;">
              <div style="" v-for="(item,index) in images2" :key="index">
                <img class="scrollimg" :src="item.url" @click="changeImg(index)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="career_down  wow animate__animated animate__zoomInUp">
        <div class="careers">
          <h2>CAREERS</h2>
          <h3>Find open roles</h3>
          <div class="careers_box">
            <div class="careers_box1">
              <div class="">
                <p>3D design</p>
              </div>
              <div class="">
                <p>Purwokerto </p>
              </div>
              <div class=""><span>Full Time</span></div>
            </div>
            <div class="careers_box2">
              <div class="">
                <p>Designer</p>
              </div>
              <div class="">
                <p>Purwokerto </p>
              </div>
              <div class=""><span>Full Time</span></div>
            </div>
            <div class="careers_box3">
              <div class="">
                <p>Art Design</p>
              </div>
              <div class="">
                <p>Purwokerto </p>
              </div>
              <div class=""><span>Full Time</span></div>
            </div>
          </div>
        </div>
      </div>
      

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
export default {
  name: 'CAREER',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      life1:'We are Playfunfun Limited',
      life2:'Join Playfunfun Limited Today!',
      life3:'We cultivate a forward-thinking, dynamic, and imaginative atmosphere, providing you with the freedom to craft your ideal games.',
      img_index:'1',
    }
  },
  computed: {
    optionLeft() {
      return {
      }
    }
  },
  methods:{
    changeImg(index){
      this.img_index = index + 1;
    }
  }
}
</script>

<style lang="scss" scoped>
@import url(@/fontCss/fontCss.css);
.career_banner_right_img{
    width: 3.1771rem;
    height: auto;
}
.career{
  padding-top: .3646rem;
  width: 100%;
  height: 100%;
  background-color: #222;
}
.career_banner{
  position: relative;
  width: 5.9375rem;
  margin: .2604rem auto 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .banner_left,.banner_right{
    width: 1.8672rem;
    height: 3.125rem;
    background-color: #000;

  }
  .banner_left{
    position: absolute;
    top: 0;
    left: -2.0313rem;
  }
  .banner_right{
    position: absolute;
    top: 0;
    right: -2.0313rem;
  }
  .career_banner_right{
    width: 3.1771rem;
    height: 2.1875rem;
    padding-top: .0521rem;
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: .0938rem;
    opacity: 0.75;
    line-height: 1.5625rem;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .career_banner_left{
    width: 2.6563rem;
    height: 2.1875rem;
    padding: .1563rem;
    background-color: #000;
    border-radius: .0521rem;
    h3{
      font-weight: 600;
      font-size: .0833rem;
      letter-spacing: .0104rem;
      color: #FB9C2C;
    }
    h4{
      line-height: .2135rem;
      font-size: .1875rem;
      font-family: var(--font-primary);
      font-weight: bold;
      color: white;
      margin-top: .0521rem;
    }
    p{
      margin-top: .2083rem;
      font-size: .0833rem;
      color: #999;
    }
  }
}

.career_down{
  width: 100%;
  height: 3.9063rem;
  padding-top: .2604rem;
  background-color: #000;
}
.careers{
  width: 5.9375rem;
  margin: 0 auto;
  h2{
    color: #FB9C2C;
    font-size: .0833rem;
    line-height: .1354rem;
  }
  h3{
    font-size: .1875rem;
    line-height: .3229rem;
    padding: 0 0 .0521rem;
    color: #fff;
  }
  .careers_box{
    width: 5.9375rem;
    background-color: #222;
    border-radius: .0521rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .careers_box1,.careers_box2,.careers_box3{
      width: 100%;
      height: .5208rem;
      padding: 0 .1563rem;
      display: flex;
      justify-content: space-between;
      div{
        display: flex;
        justify-content: space-between;
        p{
          font-size: .0729rem;
          line-height: .5208rem;
          color: #fff;
          cursor: pointer;
        }
        span{
          font-size: .0729rem;
          line-height: .5208rem;
          color: greenyellow;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
  .careers_box2{
    background-color: #333;
  }
}

/** 滚动区域样式 */
.scroll_bg_title{
  color: #fff;
  font-size: .1667rem;
  padding: .1563rem 0;

}
.seamless-warp2 {
  overflow: hidden;
  height: .625rem;
  width: 5.2083rem;
  margin: .2604rem auto;
  padding: .0521rem;
  background-color: #000;
  position: relative;
  top: 0;
  left: .3646rem;
  ul.item {
    width: 5.2083rem;
    list-style: none;
    li {
      float: left;
      margin-right: .4167rem;
    }
  }
}
.scrollimg{
  width: .7813rem;
  height: auto ;
}
</style>