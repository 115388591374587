<template>
  <div>
    <Header></Header>
    <div class="pric">
      <h2 class="title">Terms of use</h2>
      <p>The Terms of Service of this Agreement govern your relationship with Playfunfun Limited ("PlayFun" or "us") in relation to the use of PlayFun. Before accessing or using the Services, including browsing any PlayFun website or accessing games, you must agree to these Terms of Service and Privacy Policy.</p>
      <p>Now the website is owned and operated by Playfunfun Limited <strong>(FLAT A516, 5/F, EFFICIENCY HOUSE, 35 TAI YAU STREET, SAN PO KONG, KOWLOON, HONG KONG)</strong></p>
      <p>GOVERNING LAW- These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the HK laws.</p>
      <p>The Game may permit you to purchase certain other products or services("Offerings"). You acknowledge and agree that all information you provide with regards to a purchase of Offerings, including credit card or other payment information (if applicable), is accurate. current and complete, You represent and warrant that you have the legal right to use the payment method you provide to us or our payment processor including any credit card you provide when completing a transaction. We reserve the right with or without prior notice。</p>
      <h2 class="title">User code of conduct</h2>
      <p>To ensure that all users have a pleasant and safe experience with our service, you agree to abide by the following Code of conduct:</p>
      <p>Respect others：Do not harass, threaten or abuse other users. All forms of bullying, sexual harassment, racial discrimination, hate speech and violent speech are strictly prohibited.
        <br><br>No impersonation: You may not impersonate any person or entity, including, but not limited to, an employee or representative of Playfun.
      </p>
      <p>No cheating: You may not use any form of cheating software, scripts, bots, or other unauthorized third-party tools to gain an unfair advantage.</p>
      <p>No exploit: No exploit or error in the game to gain improper benefits. If you find a vulnerability, please report it to Playfun immediately.</p>
      <p>No Inappropriate Content: You may not post any illegal, obscene, defamatory, infringing, violent, hateful or other inappropriate content, including but not limited to text, images, videos and links.</p>
      <p>Violation treatment: Users who violate the above code of conduct may face penalties such as warning, temporary ban, permanent ban of accounts, etc. Specific punishment measures shall be determined by Playfunfun.</p>
      <h2 class="title">Children</h2>
      <p>By registering for an account or otherwise using the Services, you represent that you are at least 13 years old. If you are between the ages of 13 and 17, your legal guardian has reviewed and agrees to these terms.By installing, using or otherwise accessing the Services, you agree to these Terms of Service.You should not use our games or other services if you have any other concerns about providing information to us or being used for the purposes described in this terms.</p>
      <h2 class="title">Promotional Codes</h2>
      <p>
        (a)must be used in a lawful manner;
        <br><br>(b)must be used for the intended audience and purpose;
        <br><br>(c)may not be duplicated, sold or transferred in any manner, or made available by you to the general public (whether posted to a public forum, coupon collecting service, or otherwise),unless expressly permitted by us;
        <br><br>(d)may only be used pursuant to the specific terms that we establish for such Promotional Codes;
        <br><br>(e)are not valid for cash or other credits or points: and
        <br><br>(f) may expire prior to your use.
      </p>
      <h2 class="title">No Refunds</h2>
      <p>All sales are final, and the Company does not offer any money-back guarantees. You recognize and agree that you shall not be entitled to a refund for any purchase under any circumstances.</p>
      <h2 class="title">Information Collection and Use</h2>
      <p>When you play games or watch videos, we process information about these activities. This includes information such as IP address, games you've played, advertising IDs, general browser and device information (such as brand and type), and country. The information we request will be retained by us and used in accordance with this Privacy Policy.</p>
      <h2 class="title">Cookies</h2>
      <p>Cookies are files containing small amounts of data, often used as an anonymous unique identifier. This content is sent to your browser from the websites you visit and stored in your device's internal memory.
        The service does not expressly use these "cookies". However, the app may use third-party code and libraries that use "cookies" to collect information and improve the service. You can choose to accept or reject these cookies and know when a cookie is sent to your device. If you choose to refuse our cookies, you may not be able to use some parts of the Service.</p>
      <h2 class="title">Ownership of</h2>
      <p>Games and Services (including, without limitation, any games, titles, computer code, themes, objects, characters, character names, stories, dialogue, slogans, concepts, artwork, animations, sounds, musical compositions, audiovisual effects, how-tos, Moral rights, documents, in-game chats, character profile information, records of gameplay using PlayFun games, and PlayFun game client and server software) are owned by PlayFun. PlayFun retains all rights related to its games and services, including but not limited to all intellectual property or other proprietary rights.</p>
      <h2 class="title">Visiting our website</h2>
      <p>When you visit our other websites and other websites and forums that we operate (the "Sites"), we collect information about you, such as details about how you use these websites and your interactions with them . We may use the information we collect about you for a variety of purposes, including to operate and optimize the website, in connection with our advertising purposes, and for research or analysis.</p>
      <h2 class="title">Policy Supplements</h2>
      <p>PlayFunmay publish additional policies related to specific services. Your rights to use such services are governed by these specific policies and terms of service.</p>
      <h2 class="title">Marketing</h2>
      <p>Unless you opt out as described below, we and our third-party partners use the information we collect about you to send you marketing communications about other 1058 games and related services based on your interests. For example, to serve ads on our Services, or to serve ads on third-party websites, applications, and connected devices.</p>
      <h2 class="title">Advertising</h2>
      <p>
        Our games may include advertisements for third-party products and services.
        <br>PlayFun and our advertising partners collect and use information about you to improve advertising, targeting and measurement systems so that we can show you relevant third-party advertisements in our games.
        <br>Where applicable, we use CMP to collect consent and permission for our advertising-based activities. Where permitted by local law, we may default to interest-based advertising.</p>
      <h2 class="title">Your rights</h2>
      <p>You have certain rights regarding your personal information and how we process it. You can exercise these rights at any time by contacting us through any of the methods listed in the "Contact Us" section below.</p>
      <h2 class="title">Force Majeure</h2>
      <p>For PlayFun any delay or failure to perform due to reasons beyond the reasonable control of unforeseen circumstances or causes agreements, embargoes, acts of civil or military authorities, fires, floods, accidents, strikes or shortages of transport facilities, fuel, energy, labor or materials Kosters is not responsible for.</p>
      <h2 class="title">Dispute resolution</h2>
      <p>If any dispute arises between you and Playfun, the parties agree to first attempt to resolve the dispute through informal negotiations. Either party must send the other party a written notice of the dispute detailing the nature of the dispute and a proposed resolution. After receiving the notification of the dispute, we will resolve the dispute with you through friendly negotiations as soon as possible and within a short time.</p>
      <h2 class="title">Updates to Terms of Use</h2>
      <p>You acknowledge and agree that PlayFun reserves the right to update, modify or revise these Terms of Use or Privacy Policy at any time. You are advised to check the website from time to time for notifications of such updates. This document has legal effect in English only. Other language translations of this document are provided for convenience only.</p>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: "TermsPage",
  components: {
    Header, Footer
  }
}
</script>

<style lang="scss" scoped>
.pric {
  width: 100%;
  padding: .4688rem 2.3438rem;
  background-color: #1a1a1a !important;

  .title {
    color: #fff;
    font-size: 30px;
  }

  p {
    padding: 0 0 15px;
    font-size: 16px;
    color: #fff;
    line-height: 33px;

  }

  .email {
    color: #2987d4;
    text-decoration: none;
  }
}
</style>