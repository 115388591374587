//引入vue-router路由插件
import VueRouter from "vue-router";
//引入Vue
import Vue from "vue";
Vue.use(VueRouter);

//引入路由组件
import Home from '@/view/home/index'
import AboutUs from '@/view/aboutUs/index'
import Game from '@/view/game/index'
import Career from '@/view/career/index'
import PricPage from '@/view/pric/index'
import TermsPage from '@/view/terms/index'

const router = new VueRouter({
    mode: 'history',
    //配置路由
    routes:[
        {
            name:'home',
            path: '/home',// 首页
            component: Home,
        },
        {
            name:'game',
            path: '/game',// 游戏
            component: Game, 
        },
        {
            name:'career',
            path: '/career',
            component: Career,
        },
        {
            name:'aboutus',
            path: '/aboutus',// 关于我们
            component: AboutUs,
        },
        {
            name:'pric',
            path: '/pric.html',
            component: PricPage,
        },
        {
            name:'terms',
            path: '/terms.html',
            component: TermsPage,
        },
        {
            path: '*',
            redirect: '/home',
        }
    ]
  })
router.beforeEach((to,from,next)=>{
    if (to.name != from.name) {
        next();
    }else{
        next(false);
    }
})

export default router
